import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { apiDocsPlugin } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
  CatalogTableRow,
} from '@backstage/plugin-catalog';
import {
  ANNOTATION_EDIT_URL,
} from '@backstage/catalog-model';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
  DefaultTechDocsHome,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis, localOidcAuthApiRef } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import {
  AlertDisplay,
  IdentityProviders,
  OAuthRequestDialog,
  SignInPage,
  TableProps,
} from '@backstage/core-components';
import { Typography } from '@material-ui/core';
import { visuallyHidden } from '@mui/utils';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { CdnUploadImageFieldExtension } from './scaffolder/CdnUploadImage';
import { ScaffolderFieldExtensions } from '@backstage/plugin-scaffolder-react';
import { 
  EntityObjectPickerFieldExtension, 
  EntityValuePickerFieldExtension, 
  ReviewStepComponent,
  EditEntityByTemplatePage
} from '@web-seven/backstage-plugin-scaffolder-extensions'

import { useApi, configApiRef } from '@backstage/core-plugin-api';

import { AutoLogout } from '@backstage/core-components';
import { openfgaPlugin } from '@web-seven/backstage-plugin-openfga';

const ANNOTATION_DELETE_URL = "backstage.io/delete-url";

const useProviders = (): IdentityProviders => {
  const configApi = useApi(configApiRef);

  let providers: IdentityProviders = []

  if (configApi.getString("auth.environment") == "development") {
    providers.push('guest')
  }

  if (configApi.has("auth.providers.oidc")) {
    providers.push({
      id: 'oidc',
      title: 'Authentification',
      message: 'For get access, you have to sign in.',
      apiRef: localOidcAuthApiRef,
    })
  }

  return providers
}


const app = createApp({
  apis,
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  components: {
    SignInPage: props => <SignInPage {...props} auto providers={useProviders()} />,
  },
  plugins: [openfgaPlugin],
});

const customActions: TableProps<CatalogTableRow>['actions'] = [
  ({ entity }) => {
    const url = entity.metadata.annotations?.[ANNOTATION_EDIT_URL];
    return {
      icon: () => (
        <>
          <Typography style={visuallyHidden}>Edit</Typography>
          <EditIcon fontSize="small" />
        </>
      ),
      tooltip: "Edit",
      disabled: !url,
      onClick: () => {
        if (!url) return;
        window.open(url, '_blank');
      },
    };
  },
  ({ entity }) => {
    const url = entity.metadata.annotations?.[ANNOTATION_DELETE_URL];
    return {
      icon: () => (
        <>
          <Typography style={visuallyHidden}>Delete</Typography>
          <DeleteIcon fontSize="small" />
        </>
      ),
      tooltip: "Delete",
      disabled: !url,
      onClick: () => {
        if (!url) return;
        window.open(url, '_blank');
      },
    };
  }
];

const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="catalog" />} />
    <Route path="/catalog" element={<CatalogIndexPage initialKind="Environment" actions={customActions}/>} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route
      path="/create"
      element={
        <ScaffolderPage
          components={{
            ReviewStepComponent,
            EXPERIMENTAL_TemplateWizardPageComponent: EditEntityByTemplatePage,
          }}
          groups={[
            {
              title: "CRS Templates",
              filter: entity =>
                entity?.metadata?.tags?.includes('crs') ?? false,
            },
            {
              title: "Platform Templates",
              filter: entity =>
                entity?.metadata?.tags?.includes('platform') ?? false,
            },
          ]}
        />
      }
    >
      <ScaffolderFieldExtensions>
        <CdnUploadImageFieldExtension />
        <EntityObjectPickerFieldExtension />
        <EntityValuePickerFieldExtension />
      </ScaffolderFieldExtensions>
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />}>
      <DefaultTechDocsHome />
    </Route>
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AutoLogout />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
